// 공통 리스트 타입
export type BackReponseType = {
  code: string;
  data: any;
  msg: string;
};
export interface ListInfo {
  currentPage: number;
  totalCount: number;
  totalPage: number;
}

// 일반 정보에서의 공통 속성
interface CommonTableInfo {
  artistNameKo: string;
  openYn: 'Y' | 'N';
  rgstDt: string;
  uploaderNickname: string;
  artistUseYn: 'Y' | 'N';
}

export type images = {
  imgUrl: string;
  orderSeq: number;
  seq: number;
  flag?: 'default' | 'new' | 'delete';
};

// 멤버관리 - 계정 타입
export type AdminAccountInfo = {
  adminId: string;
  adminName: string;
  lastLoginTime: string;
  allowYn: 'Y' | 'N';
  adminEmail: string;
};

export type TermsDocType =
  | 'PRIVACY_POLICY'
  | 'TERMS_OF_SERVICE'
  | 'PARTNER_CENTER_TERMS_OF_SERVICE'
  | 'PARTNER_CENTER_PRIVACY_POLICY';

export type TermsVersionType = {
  seq: number;
  version: string;
};

// 약관 및 추천 URL 관리 - 약관 타입
export type TermsInfo = {
  version: string;
  rgstDt: string;
  seq: number;
};

// 약관 및 추천 URL 관리 - 약관 상세 타입
export type TermsDetailInfo = {
  content: string;
  rgstDt: string;
  seq: number;
  updtDt: string;
  version: string;
};

// 약관 및 추천URL 관리 - 개인정보처리방침
export interface TermsListInfo extends ListInfo {
  data: TermsInfo[];
  additionalData: null;
}

export type AgencyType = {
  agencyId: string;
  agencyName: string;
  managedArtists: string;
};
export type AgencyArtistType = {
  artistId: string;
  nameKo: string;
  checkYn: 'Y' | 'N';
};
export type AgencyDataType = {
  agencyId: string;
  agencyName: string;
  artistList: AgencyArtistType[];
};
export type AgencyMananerType = {
  uid: string;
  agencyName: string;
  email: string;
  name: string;
  accountType: string;
  position: string;
  managedArtists: string;
};
export type AgencyUserDetailType = {
  uid: string;
  agencyName: string;
  email: string;
  name: string;
  accountType: string;
  position: string;
  accountTypeCode: string;
  artistList: {
    artistId: string;
    nameKo: string;
  }[];
};
export type AgencyManagerAccountType = {
  code: string;
  codeName: string;
};
export type AgencyNoticeArtistType = {
  artistId: string;
  nameKo: string;
};
export type AgencyNoticeListInfo = {
  totalCount: number;
  currentPage: number;
  totalPage: number;
  data: {
    noticeId: string;
    title: string;
    artistName: string;
    rgstAuthorType: string;
    rgstDt: string;
    viewCnt: number;
    fixedYn: 'Y' | 'N';
    openYn: 'Y' | 'N';
    bookedYn: 'Y' | 'N';
    bookedDt: string;
  }[];
};
export type AgencyNoticeTempType = {
  noticeId: string;
  title: string;
  artistName: string;
  updtId: string;
  updtDt: string;
};
export type AgencyNoticeDetailType = {
  noticeId: string;
  artistName: string;
  title: string;
  content: string;
  youtubeUrl: string;
  linkUrl: string;
  fixedYn: 'Y' | 'N';
  temporaryYn: 'Y' | 'N';
  openYn: 'Y' | 'N';
  rgstPosition: string;
  rgstId: string;
  rgstDt: string;
  updtPosition: string;
  updtId: string;
  updtDt: string;
  viewCnt: number;
  imgUrlList: string[];
  bookedDt: string;
  bookedYn: 'Y' | 'N';
};

// ---------------------------------------------------------------------------------------------------------
// ------------------------------------------유저 관리 시작--------------------------------------------------
// ---------------------------------------------------------------------------------------------------------

// 유저 관리 - 아티스트 타입
export type ArtistInfo = {
  artistId: string;
  artistNameKo: string;
  groupType: string;
  artistFeedCnt: number;
  calendarCnt: number;
  duckCnt: number;
  goodTalkCnt: number;
  mailBoxCnt: number;
  membershipYn: 'Y' | 'N';
  membershipSaleYn: 'Y' | 'N';
  artistUseYn: 'Y' | 'N';
};

// 아티스트 관리 - 멤버십 상태에 따른 QueryParams 타입 정의
export type QueryParams = {
  membershipYn?: 'Y' | 'N';
  membershipSaleYn?: 'Y' | 'N';
};

// 유저 관리 - 가입유저 타입
export type UserInfo = {
  email: string;
  artistNameKo: string;
  duckRgstDt: string | null;
  isMembership: string;
  uid: string;
  userNickname: string | null;
  userRgstDt: string;
  duckCnt: number;
};

export type UserDetailInfo = {
  userBasicInfoList: UserBasicInfo[];
  userMembershipInfoList: UserMembershipInfo[];
};

export type UserBasicInfo = {
  artistId: string;
  uid: any;
  artistNameKo: string;
  artistUseYn: 'Y' | 'N';
  duckRgstDt: string | null;
  userProfileId: string;
  email: string;
  userNickname: string | null;
  userRgstDt: string;
};

export type UserMembershipInfo = {
  uid: any;
  artistId: any;
  membershipId: string;
  artistNameKo: string;
  artistUseYn: 'Y' | 'N';
  endDt: string;
  name: string;
  rgstDt: string;
  startDt: string;
  cancelled: boolean;
};

export type UserQueryKeyType = 'ARTIST_ID' | 'EMAIL' | 'NICKNAME';

// 아티스트 상세 정보 - 아티스트 기본 정보 (생성용)
export interface CreateArtistBasicInfo {
  about: string;
  artistColor: string | null;
  artistMainImgUrl: string;
  artistNameEn: string;
  artistNameKo: string;
  artistRecommendProfileImgUrl: string | null;
  genre: string;
  groupType: string;
}
// 아티스트 상세 정보 - 아티스트 기본 정보
export interface ArtistBasicInfo extends CreateArtistBasicInfo {
  artistId: string;
  membershipSaleYn: 'Y' | 'N';
  membershipYn: 'Y' | 'N';
  artistUseYn: 'Y' | 'N';
}
// 아티스트 상세 정보 - 아티스트 매니저 정보 (생성용)
export interface CreateArtistManagerAccountInfo {
  email: string;
  nickName: string;
  profileImgUrl: string;
}
// 아티스트 상세 정보 - 아티스트 매니저 정보
export interface ArtistManagerAccountInfo extends CreateArtistManagerAccountInfo {
  profileId: string | null;
  uid: string | null;
  // flag: "default" | "new" | "origin";
  useYn: 'Y' | 'N';
}
// 아티스트 상세 정보 - 아티스트 멤버 정보 (생성용)
export interface CreateArtistMemberAccountInfo {
  email: string;
  fullName: string | null;
  nickName: string;
  profileImgUrl: string;
  emailCheck?: boolean;
  nameCheck?: boolean;
}
// 아티스트 상세 정보 - 아티스트 멤버 정보
export interface ArtistMemberAccountInfo extends CreateArtistMemberAccountInfo {
  uid: string | null;
  profileId: string | null;
  // flag: "default" | "new" | "origin";
  useYn: 'Y' | 'N';
}
// 아티스트 상세 정보 - 음악 플랫폼 정보 (생성용)
export type CreateMusicPlatformInfo = {
  siteType: string;
  siteName: string;
  siteUrl: string;
  checked: boolean;
};

// 아티스트 상세 정보 - 음악 플랫폼 정보 (생성용)
export type UpdateMusicPlatformInfo = {
  checked: 'Y' | 'N';
  orderSeq: number | null;
  seq: number | null;
  siteName: string;
  siteType: string;
  siteUrl: string | null;
};
// 아티스트 상세 정보 - 음악 플랫폼 정보
export type MusicPlatformInfo = {
  checked: 'Y' | 'N';
  orderSeq: number | null;
  seq: number | null;
  siteName: string;
  siteType: string;
  siteUrl: string | null;
};
// 아티스트 상세 정보 - 유튜브 정보 (생성용)
export interface CreateYoutubeInfo {
  mainYn: 'Y' | 'N';
  youtubeTitle: string;
  youtubeUrl: string;
}
// 아티스트 상세 정보 - 유튜브 정보
export interface YoutubeInfo extends CreateYoutubeInfo {
  orderSeq: number;
  seq: number;
  flag: 'default' | 'new' | 'origin' | 'delete';
}

// 아티스트 상세 정보
export type ArtistDetailInfo = {
  artistBasicInfo: ArtistBasicInfo;
  artistManagerAccount: ArtistManagerAccountInfo;
  artistMemberAccountList: ArtistMemberAccountInfo[];
  musicPlatformList: MusicPlatformInfo[];
  youtubeList: YoutubeInfo[];
};

export type PostStaticInfo = {
  artistFeedCnt: number;
  calendarCnt: number;
  cheerUpCnt: number;
  fanOnlyCnt: number;
  galleryCnt: number;
  goodTalkCnt: number;
  infoRoomCnt: number;
  mailBoxCnt: number;
};

export interface MembershipNewType {
  amount: number;
  amountd: number;
  imgUrl: string;
  name: string;
  period: number | string;
  detail: string;
  benefits: string;
}

export interface MembershipType extends MembershipNewType {
  membershipSaleYn: 'Y' | 'N';
  saleEndDt: string;
  saleStartDt: string;
  status: string;
  userCnt: number;
  userKrwCnt: number;
  userUsdCnt: number;
  productId: string;
}
export type MembershipHistoryType = {
  totalCash: number;
  MHistory: {
    sellStatus: string;
    name: string;
    period: string;
    salePeriod: string;
    userCnt: string;
    userKrwCnt: string;
    userUsdCnt: string;
    amount: string;
    amountd: string;
    totalAmount: string;
    totalUSDAmount: number;
    productId: string;
  }[];
};

// ---------------------------------------------------------------------------------------------------------
// ------------------------------------------유저 관리 끝----------------------------------------------------
// ---------------------------------------------------------------------------------------------------------

export type ArtistId = {
  artistId: string;
  artistNameKo: string;
  artistUseYn: 'Y' | 'N';
};

export type GoodNewsInfo = {
  temporaryYn: 'Y' | 'N';
  rgstDt: string;
  seq: number;
  title: string;
  viewCnt: number;
  bookedDt: string;
  bookedYn: 'Y' | 'N';
};

export interface CreateGoodNewsType {
  temporaryYn: 'Y' | 'N';
  linkUrl: string | null;
  content: string;
  thumbImgUrl: string | null;
  title: string;
  youtubeUrl: string | null;
  bookedDt: string | null;
}

export interface GoodNewsDetailInfo extends CreateGoodNewsType {
  linkName: string | null;
  rgstDt: string;
  rgstId: string;
  seq: number;
  updtDt: string;
  updtId: string | null;
  bookedYn?: 'Y' | 'N';
  bookedDt: string | null;
  openYn: 'Y' | 'N';
}

export interface CreateGoodduckMagazineType {
  artistId: string;
  content: string;
  linkUrl: string | null;
  mainImgUrl: string;
  moreMagazineList: MoreMagazineInfo[];
  subTitle: string;
  temporaryYn: 'Y' | 'N';
  thumbImgUrl: string;
  title: string;
  youtubeUrl: string | null;
  bookedDt: string | null;
  bookedYn: 'Y' | 'N';
}

export type GoodduckMagazineInfo = {
  magazineId: string;
  temporaryYn: 'Y' | 'N';
  rgstDt: string;
  title: string;
  viewCnt: number;
  bookedDt: string | null;
  bookedYn: 'Y' | 'N';
};

export interface magazineSelectInfo {
  magazineId: string;
  title: string;
  moreMagazineSeq?: number;
  flag?: 'in' | 'new' | 'delete';
}

export interface MoreMagazineInfo {
  moreMagazineSeq?: number;
  magazineId: string;
  artistNameKo?: string;
  title: string;
  flag?: 'in' | 'new' | 'delete';
}

export type GoodduckMagazineDetailInfo = {
  title: string;
  subTitle: string | null;
  artistNameKo: string;
  rgstDt: string;
  rgstId: string;
  content: string;
  moreMagazineList: MoreMagazineInfo[];
  linkName: string | null;
  linkUrl: string | null;
  openYn: 'Y' | 'N';
  thumbImgUrl: string | null;
  updtDt: string;
  updtId: string | null;
  youtubeUrl: string | null;
  magazineId: string;
  artistId: string;
  mainImgUrl: string | null;
  temporaryYn: 'Y' | 'N';
  bookedDt: string | null;
  bookedYn: 'Y' | 'N';
};

// 아티스트 페이지 관리 - 아티스트 피드
export interface ArtistFeedInfo extends CommonTableInfo {
  artistFeedId: string;
  content: string;
  publicReleaseDt: string | null;
}

// 아티스트 페이지 관리 - 아티스트 피드 상세 보기
export interface ArtistFeedDetailInfo extends CommonTableInfo {
  artistFeedId: string;
  content: string;
  imgList: images[];
  linkUrl: string | null;
  youtubeUrl: string | null;
  type: 'tempporary' | 'permanent' | 'all' | 'all_temporary';
  membershipReleaseYn: 'Y' | 'N';
  publicReleaseDt: string | null;
}

// 아티스트 페이지 관리 - 굿덕 사서함
export interface MailBoxesInfo extends CommonTableInfo {
  audioFileName: string;
  audioFileUrl: string;
  mailBoxId: string;
  title: string;
  artistUseYn: 'Y' | 'N';
  playCnt: number;
}

// 아티스트 페이지 관리 - 굿덕 사서함 상세
export interface MailBoxesDetailInfo extends CommonTableInfo {
  artistUseYn: 'Y' | 'N';
  audioDuration: number;
  audioFileName: string;
  audioFileUrl: string;
  mailBoxId: string;
  playCnt: number;
  title: string;
  membershipReleaseYn: 'Y' | 'N';
  publicReleaseDt: string | null;
}

// 아티스트 페이지 관리 - 굿톡
export interface GoodTalkInfo extends CommonTableInfo {
  replayAudioUrl: string;
  goodTalkId: string;
  title: string;
  artistUseYn: 'Y' | 'N';
  playCnt: number;
  goodTalkMemoCnt: number;
  startDt: string;
}

// 아티스트 페이지 관리 - 굿톡 상세
export interface GoodTalkDetailInfo extends CommonTableInfo {
  artistUseYn: 'Y' | 'N';
  goodTalkId: string;
  playCnt: number;
  title: string;
  startDt: string;
  endDt: string;
  goodTalkMemoCnt: number;
  goodTalkVoteCnt: number;
  hasQAndA: 'Y' | 'N';
  imgUrlList: images[];
  replayAudioLength: string;
  replayAudioUrl: string;
}

// 아티스트 페이지 관리 - 갤러리
export interface GalleriesInfo extends CommonTableInfo {
  content: string;
  galleryId: string;
  tag: string;
  uid: string;
}

// 아티스트 페이지 관리 - 갤러리 상세 보기
export interface GalleryDetailInfo extends CommonTableInfo {
  content: string;
  galleryId: string;
  imgList: images[];
  tag: string;
  uid: string;
}

// 아티스트 페이지 관리 - 응원글
export interface CheerUpInfo extends CommonTableInfo {
  artistUseYn: 'Y' | 'N';
  cheerUpId: string;
  content: string;
  uid: string | null;
}

// 아티스트 페이지 관리 - 응원글 상세보기
export interface FanOnlyDetailInfo extends CommonTableInfo {
  content: string;
  fanOnlyId: string;
  imgList: images[];
  linkUrl: string | null;
  youtubeUrl: string | null;
  artistUseYn: 'Y' | 'N';
  uid: string | null;
}

// 아티스트 페이지 관리 - 팬끼리
export interface FanOnlyInfo extends CommonTableInfo {
  content: string;
  fanOnlyId: string;
  artistUseYn: 'Y' | 'N';
  uid: string | null;
}

// 아티스트 페이지 관리 - 팬끼리 상세보기
export interface FanOnlyDetailInfo extends CommonTableInfo {
  content: string;
  fanOnlyId: string;
  imgList: images[];
  linkUrl: string | null;
  youtubeUrl: string | null;
  artistUseYn: 'Y' | 'N';
  uid: string | null;
}

// 아티스트 페이지 관리 - 자유게시판
export interface InfoRoomsInfo extends CommonTableInfo {
  artistUseYn: 'Y' | 'N';
  infoRoomId: string;
  tag: string;
  title: string;
  uid: string | null;
  viewCnt: number;
}

// 아티스트 페이지 관리 - 자유게시판 상세보기
export interface InfoRoomsDetailInfo extends CommonTableInfo {
  content: string;
  infoRoomId: string;
  imgList: images[];
  linkUrl: string | null;
  youtubeUrl: string | null;
  artistUseYn: 'Y' | 'N';
  tag: string;
  title: string;
  uid: string | null;
  viewCnt: number;
}

// =======================================캘린더 관리 시작 =================================================

// 캘린더 관리 - 캘린더
export interface CalendarInfo extends CommonTableInfo {
  calendarId: string;
  tag: string;
  title: string;
  viewCnt: number;
  rgstId: string;
  scheduleDt: string;
  scheduleTime: string | null;
}

// 캘린더 관리 - 캘린더 생성
export type PostCalendarInfo = {
  artistId: string;
  content: string;
  imgUrlList: string[];
  linkUrl: string;
  scheduleDt: string;
  scheduleTime: string;
  tag: string;
  title: string;
  youtubeUrl: string;
};

// 캘린더 관리 - 캘린더 상세
export interface CalendarDetailInfo extends CommonTableInfo {
  calendarId: string;
  content: string;
  imgList: images[];
  linkUrl: string | null;
  scheduleDt: string;
  rgstId: string;
  scheduleTime: string;
  tag: string;
  title: string;
  viewCnt: number;
  youtubeUrl: string | null;
}

// 캘린더 관리 - 일정 제보
export interface TipOffInfo extends CommonTableInfo {
  checkYn: 'Y' | 'N';
  rgstYn: 'Y' | 'N';
  scheduleDt: string;
  scheduleTime: string | null;
  tipOffScheduleId: string;
  title: string;
  uid: string | null;
}

// 캘린더 관리 - 일정 제보 상세
export interface TipOffDetailInfo extends CommonTableInfo {
  checkYn: 'Y' | 'N';
  content: string;
  rgstYn: 'Y' | 'N';
  scheduleDt: string;
  scheduleTime: string;
  title: string;
  uid: string | null;
  tipOffScheduleId: string;
}

// 캘린더 관리 - 캘린더 리스트 타입
export interface CalendarListInfo extends ListInfo {
  data: CalendarInfo[];
  additionalData: null;
}

// 캘린더 관리 - 일정 제보 리스트 타입
export interface TipOffListInfo extends ListInfo {
  data: TipOffInfo[];
  additionalData: null;
}

// =======================================캘린더 관리 끝 ==================================================

// 댓글 관리 - 댓글 리스트
export interface ReplyInfo extends CommonTableInfo {
  content: string;
  reviewId: string;
  uid: string;
}

// 배지 관리 - 배지 정보
export interface BadgeInfo {
  badgeId: string;
  desc: string;
  name: string;
}

// 배지 관리 - 배지 카운트 정보
export interface profileCount {
  artistId: string;
  artistNameKo: string;
  artistUseYn: 'Y' | 'N';
  badgeId: string;
  badgeName: string;
  profileCnt: number;
}

// 배지 관리 - 배지 상세 정보
export interface BadgeDetailInfo extends BadgeInfo {
  imgUrl: string;
  profileCntList: profileCount[];
}

// ---------------------------------------------------------------------------------------------------------
// ------------------------------------------신고 관리 시작--------------------------------------------------
// ---------------------------------------------------------------------------------------------------------

// 신고내역관리 - 게시글 리스트
export interface ReportType {
  contentsId: string;
  reportContent: string | null;
  reportNickname: string;
  reportResult: string | null;
  rgstDt: string;
  seq: number;
  type: string;
  uid: string;
}

export interface ReportPopupInfo extends ReportType {
  artistNameKo: string;
  artistUseYn: 'Y' | 'N';
  content: string;
  openYn: 'Y' | 'N';
}

// ---------------------------------------------------------------------------------------------------------
// ------------------------------------------신고 관리 끝----------------------------------------------------
// ---------------------------------------------------------------------------------------------------------

// 멤버 관리 - 계정 리스트 타입
export interface AdminAccountListInfo extends ListInfo {
  data: AdminAccountInfo[];
}

// 유저 관리 - 가입 유저 리스트 타입
export interface UserListInfo extends ListInfo {
  additionalData: {
    totalProfileCnt: number;
  };
  data: UserInfo[];
}

// 유저 관리 - 아티스트 리스트 타입
export interface ArtistListInfo extends ListInfo {
  data: ArtistInfo[];
}

// 홈 게시물 관리 - 굿뉴스
export interface GoodNewsListInfo extends ListInfo {
  data: GoodNewsInfo[];
}

// 홈 게시물 관리 - 굿덕매거진 리스트 타입
export interface GoodduckMagazineListInfo extends ListInfo {
  data: GoodduckMagazineInfo[];
}

export interface GoodduckMoreMagazineListInfo extends ListInfo {
  data: MoreMagazineInfo[];
  additionalData: null;
}

// 아티스트 페이지 관리 - 아티스트 피드 리스트 타입
export interface ArtistFeedListInfo extends ListInfo {
  data: ArtistFeedInfo[];
}

// 아티스트 페이지 관리 - 굿덕사서함 리스트 타입
export interface MailBoxesListInfo extends ListInfo {
  data: MailBoxesInfo[];
  additionalData: null;
}

// 아티스트 페이지 관리 - 굿톡 리스트 타입
export interface GoodTalkListInfo extends ListInfo {
  data: GoodTalkInfo[];
  additionalData: {
    totalGoodTalkMemoCnt: number;
  };
}

// 아티스트 페이지 관리 - 갤러리 리스트 타입
export interface GalleriesListInfo extends ListInfo {
  data: GalleriesInfo[];
  additionalData: null;
}

// 아티스트 페이지 관리 - 응원글 리스트 타입
export interface CheerUpListInfo extends ListInfo {
  data: CheerUpInfo[];
  additionalData: null;
}

// 아티스트 페이지 관리 - 팬끼리 리스트 타입
export interface FanOnlyListInfo extends ListInfo {
  data: FanOnlyInfo[];
  additionalData: null;
}

// 아티스트 페이지 관리 - 자유게시판 리스트 타입
export interface InfoRoomsListInfo extends ListInfo {
  data: InfoRoomsInfo[];
  additionalData: null;
}

// 댓글 관리 - 댓글 리스트 타입
export interface ReplyListInfo extends ListInfo {
  data: ReplyInfo[];
  additionalData: null;
}

// 신고내역 관리 - 게시글 리스트 타입
export interface BoardReportListInfo extends ListInfo {
  data: ReportType[];
  additionalData: null;
}

// 페이지를 변경하는데 사용되는 state 타입
export type ChangeListInfo = {
  page: number;
  keyword: string;
  queryKey: string;
  artistId: string;
  limit: number;
  sortKey: string;
  sortMethod: string;
};

// limit 변경에 따른 함수의 타입
export type ChangeLimitType = (
  e: React.ChangeEvent<HTMLSelectElement>,
  listInfo: ListInfo | null,
  changeInfo: ChangeListInfo,
  setChangeInfo: React.Dispatch<React.SetStateAction<ChangeListInfo>>,
) => void;

export type CodeType = {
  code: string;
  codeName: string;
};

// Custom error type
export class ApiError extends Error {
  public code: string;
  public msg: string;

  constructor(code: string, msg: string) {
    super(msg); // Error의 기본 message 필드에 msg를 전달
    this.code = code;
    this.msg = msg;
  }
}
